<template>
  <div class="container">
    <div class="content bg-gray">
      <section class="section inner-H inner-V bg-gray">
        <div class="finish">
          <img
            class="eKYC error-icon ta-c"
            src="../../../public/assets/img/eKYC/screen_icon_caution_ekyc.svg"
          />
          <h2 class="headline headline-lg">{{ dataError.title }}</h2>
          <p class="finish-text" v-html="dataError.message"></p>
          <router-link class="btn btn-bd-white" :to="{ name: 'EKYCEntry' }">最初の画面へ戻る</router-link>
        </div>
      </section>
    </div>
    <Footer class="bg-gray" :menu="false" />
  </div>
</template>

<script>
import { EKYC_MESSAGE_ERROR } from '@/config/message';
export default {
  name: 'EntryError',
  data: function () {
    return {
      dataError: {
        title: '',
        message: '',
      },
    };
  },
  watch: {
    entryCode(errorCode) {
      this.showError(errorCode);
    },
  },
  computed: {
    entryCode() {
      return this.$route.params.entryCode;
    },
  },
  methods: {
    navigateErrorPage() {
      this.$router.push({ name: '404' });
    },
    showError(errorCode) {
      const error = EKYC_MESSAGE_ERROR[errorCode]
      if (!error) {
        this.navigateErrorPage();
      } else {
        this.dataError.title = error.title;
        this.dataError.message = error.message;
      }
    },
  },
  mounted() {
    this.showError(this.entryCode);
  },
};
</script>
