export const EKYC_MESSAGE_ERROR = {
  'ng_1': {
    title: "認証に失敗しました",
    message: "認証できませんでした。<br />最初から再度お試しください。"
  },
  'ng_2': {
    title: "認証に失敗しました",
    message: "認証できませんでした。<br />最初から再度お試しください。"
  },
  // 2: {
  //   title: "通信エラー",
  //   message: "通信環境をご確認のうえ <br />最初から再度お試しください。"
  // },
  'ng_5': {
    title: "セッションアウト",
    message: "認証できませんでした。<br />最初から再度お試しください。"
  },
  'ng_4': {
    title: "システムエラー",
    message: "認証できませんでした。<br />最初から再度お試しください。"
  },
  // 5: {
  //   title: "予期せぬエラーが発生しました",
  //   message: "通信環境をご確認のうえ <br />最初から再度お試しください。"
  // }
}